import { useEffect, useRef } from 'react';

import type { Nullable } from '@teampetfriends/util-types';

import {
  type Event,
  type EventSourcePolyfillInit,
  EventSourcePolyfill,
} from 'event-source-polyfill';

export interface UseSSEStreamingParams<T> extends EventSourcePolyfillInit {
  url: string;
  enabled?: boolean;
  onOpen?: (event: Event) => void;
  onError?: (event: Event) => void;
  onMessage?: (data: { data: T; lastEventId: string }) => void;
}

const IGNORE_MESSAGES = ['EventStream Created.', 'Heartbeat'];

export const useSSEStreaming = <T>({
  url,
  enabled = true,
  onOpen,
  onError,
  onMessage,
  ...options
}: UseSSEStreamingParams<T>) => {
  const eventSource = useRef<Nullable<EventSource>>(null);

  const disconnect = () => {
    eventSource?.current?.close();
    eventSource.current = null;
  };

  const onMessageListener = (event: MessageEvent) => {
    if (
      typeof event.data === 'string' &&
      IGNORE_MESSAGES.some((message) => event.data.includes(message))
    )
      return false;

    onMessage?.({
      ...event,
      data: JSON.parse(event.data),
    });
  };

  const connect = async () => {
    if (!enabled || eventSource.current) return;

    const listener = new EventSourcePolyfill(url, { ...options });

    eventSource.current = listener;

    if (onOpen) listener.onopen = onOpen;

    if (onError) listener.onerror = onError;
  };

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (onMessage && eventSource.current) eventSource.current.onmessage = onMessageListener;

    return () => {
      if (onMessage && eventSource.current) eventSource.current.onmessage = null;
    };
  }, [eventSource.current, onMessage]);
};
