import BasicTable from './BasicTable';
import type { TableProps } from './type';
import VirtualTable from './VirutalTable';

import './table.css';

/**
 *
 * @type virtualizedEnabled : (default: false) 테이블에 페이지네이션이 없거나, 한번에 데이터 리스트를 많이 보여주는 경우 사용, (주의사항!! 1. 컬럼의 사이즈는 고정이어야 함. 컬럼의 사이즈 꼭 확인하시고, 셀의 overflow 확인 부탁 / 2. 반드시 height를 지정해줘야 됨. 반드시!)
 * @description 가상화 테이블을 사용하려면, width가 %가 아닌 고정 값(px)일때 사용할 수 있다.
 * @description 위의 조건 관련 tanstack issue {@link https://github.com/TanStack/virtual/issues/585}, {@link https://github.com/TanStack/virtual/issues/640}
 * @returns
 */
function Table<T>({
  virtualizedEnabled = false,
  ...rest
}: TableProps<T> & { virtualizedEnabled?: boolean }) {
  if (virtualizedEnabled) return <VirtualTable {...rest} />;

  return <BasicTable {...rest} />;
}

export default Table;
