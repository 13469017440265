import { useCallback } from 'react';

import { useAtom, useSetAtom } from 'jotai';

import { useSSEStreaming } from '@hooks/useSSEStreaming';
import { authStore } from '@hooks/useSyncAuth';
import { type Alarm, alarmAtom, alarmCountAtom } from '@store/atoms/alarm';

import { checkDuplicateWithNotificationId } from '../util';

export const useAlarmStreaming = ({ enabled = true }: { enabled: boolean }) => {
  const [alarms, setAlarms] = useAtom(alarmAtom);
  const setAlarmCount = useSetAtom(alarmCountAtom);

  const {
    cookie: [accessToken],
  } = authStore.get(['accessToken']);

  useSSEStreaming<Alarm>({
    url: `${import.meta.env.VITE_BASE_VENDOR_URL}/portals/notifications/subscribe`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    heartbeatTimeout: 1000 * 61,
    enabled,
    onError: (event) => {
      return false;
    },
    onMessage: useCallback(
      (event: { data: Alarm; lastEventId: string }) => {
        if (!event.data) return false;

        const message = event.data;

        const removeDuplicateMessage = checkDuplicateWithNotificationId([message, ...alarms]);

        setAlarms(removeDuplicateMessage);

        setAlarmCount((prev) => prev + 1);
      },
      [alarms]
    ),
  });
};
