import dayjs from 'dayjs';

import { getCalcDate } from '@utils/util';

import type { DeliveryNoticeDetail, DeliveryNoticeParams } from './interfaces';

export const getDeliveryNoticeInitialState = (): DeliveryNoticeParams => {
  const startDate = getCalcDate(new Date(), 1, 'month');

  return {
    dateCondition: 'PRODUCT_NOTICE_CREATED_DATE',
    startDate,
    endDate: new Date(),
    deliveryNoticeStatuses: null,
    deliveryNoticeTypes: null,
    searchCondition: 'TITLE',
    searchContents: '',
    pageNumber: 1,
    pageSize: 10,
  };
};

const defaultTime = dayjs().toString();

export const DEFAULT_DELIVERY_NOTICE_DETAIL: DeliveryNoticeDetail = {
  deliveryNoticeId: 0,
  title: '',
  publishStartDate: defaultTime,
  publishEndDate: defaultTime,
  status: '',
  noticeType: '',
  mappingType: '',
  holiDayInfo: {
    outboundClosedAt: defaultTime,
    outboundResumeDay: defaultTime,
  },
  delayInfo: {
    delayDays: '',
    delayReason: '',
  },
  preOrderInfo: {
    preOrderStartDay: defaultTime,
    preOrderEndDay: defaultTime,
    outboundStartDay: defaultTime,
    quantity: 0,
  },
  targetBrands: [],
  targetProducts: [],
  createdAt: null,
  workerName: null,
};
