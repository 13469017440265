import { ReactNode } from 'react';

import { Box, CSS, Flex, Typo } from '@teampetfriends/react-ui';

import { GreyArrow } from '@icons/svg';
import { allowNegative, thousandComma } from '@utils/util';

type CommonCSS = { css?: CSS };

interface WhiteItemWrapperProps extends CommonCSS {
  children: ReactNode;
  onClick?: () => void;
}

interface WhiteItemHeadProps extends CommonCSS {
  title: string;
}

interface WhiteItemContentProps extends CommonCSS {
  value: string | number;
  unit: '건' | '원';
}

function WhiteItemWrapper({ children, css, onClick }: WhiteItemWrapperProps) {
  return (
    <Flex
      css={{ background: 'var(--color-white)', borderRadius: '1rem', cursor: 'pointer', ...css }}
      onClick={onClick}
    >
      {children}
    </Flex>
  );
}

function WhiteItemHead({ title, css }: WhiteItemHeadProps) {
  return <Typo css={{ fontSize: '1.6rem', fontWeight: 600, ...css }}>{title}</Typo>;
}

function WhiteItemArrow() {
  return (
    <Box>
      <GreyArrow />
    </Box>
  );
}

function WhiteItemContent({ value, unit, css }: WhiteItemContentProps) {
  const valueWithThousandsComma = thousandComma(allowNegative(String(value)));

  return (
    <Typo css={{ fontWeight: 700, fontSize: '1.8rem', ...css }}>
      {valueWithThousandsComma}
      {unit}
    </Typo>
  );
}

const Wrapper = WhiteItemWrapper;
const Head = WhiteItemHead;
const Arrow = WhiteItemArrow;
const Content = WhiteItemContent;

export { Arrow, Content, Head, Wrapper };
