import type { Nullable } from '@teampetfriends/util-types';

import { atom, useAtom } from 'jotai';

import { useQuery } from '@tanstack/react-query';
import { pureClient } from '@utils/client';

import type { OptionKey, OptionResponse } from './type';

const getOptions = async () => {
  const { data } = await pureClient(false).get<OptionResponse>('/enum/items');
  return data;
};

export const optionAtom = atom<Nullable<OptionResponse>>(null);

function useOptions(key: OptionKey[]): OptionResponse[OptionKey][] | [] {
  const [options, setOptions] = useAtom(optionAtom);

  useQuery({
    queryKey: ['option', JSON.stringify(key)],
    queryFn: getOptions,
    enabled: !options,
    onSuccess: (res) => {
      setOptions(res);
    },
    onError: () => {
      setOptions(null);
    },
  });

  return options ? key.map((optionKey) => options[optionKey]) : [];
}

export default useOptions;
