import { Box } from '@teampetfriends/react-ui';

import { getCouponRequestInitialState } from '@coupon/CouponRequest/modules/constant';
import { useRoute } from '@hooks/useRoute';
import { getInboundRequestInitialState } from '@inbound/InboundRequest/modules/constant';
import { getInformationRequestInitialState } from '@information/InformationRequest/modules/constant';
import { PRODUCT_REQUEST_INITIAL_STATE } from '@product/ProductRequest/modules/constant';
import { getCalcDate } from '@utils/util';

import { useGetRequestSummaryWithDashboard } from '../../apis';
import { Board, BoardTitle } from '../Board';

import RequestItem from './modules/components/RequestItem';
import { getDeliveryNoticeInitialState } from '@delivery/DeliveryNotice/modules/constant';

const BREAK_POINT_01 = 1300;

function RequestSection() {
  const { push } = useRoute();
  const couponDefaultParams = getCouponRequestInitialState();
  const inboundDefaultParams = getInboundRequestInitialState();
  const informationDefaultParams = getInformationRequestInitialState();
  const deliveryNoticeDefaultParams = getDeliveryNoticeInitialState();

  const { data } = useGetRequestSummaryWithDashboard();
  const {
    productRegisterRequestedCount,
    productConnectionRequestedCount,
    productModifyRequestedCount,
    productStatusRequestedCount,
    productPriceRequestedCount,
    inboundRequestedCount,
    inboundApprovedCount,
    couponIssueRequestedCount,
    vendorSettlementModifyRequestedCount,
    vendorBusinessModifyRequestedCount,
    productDeliveryNoticeRequestedCount,
  } = data;

  const onClickNavigateWithParams = ({
    path,
    params,
  }: {
    path: string;
    params: Record<string, any>;
  }) => {
    push(path, params);
  };

  return (
    <Board css={{ background: '#F9F9FA' }}>
      <BoardTitle title='요청' description='최근 1개월 기준' css={{ pb: '2rem' }} />
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: '1fr 1fr',
          gap: '1.6rem',
          mt: '2rem',
          [`@media (max-width: ${BREAK_POINT_01}px)`]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
        }}
      >
        <RequestItem
          value={productRegisterRequestedCount}
          title='상품등록 요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/admin/request/list',
              params: {
                ...PRODUCT_REQUEST_INITIAL_STATE,
                actions: ['REGISTER'],
                statuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={productConnectionRequestedCount}
          title='상품연결 요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/admin/request/list',
              params: {
                ...PRODUCT_REQUEST_INITIAL_STATE,
                actions: ['CONNECTION'],
                statuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={productModifyRequestedCount}
          title='상품정보 변경요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/admin/request/list',
              params: {
                ...PRODUCT_REQUEST_INITIAL_STATE,
                actions: ['MODIFY'],
                statuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={productStatusRequestedCount}
          title='판매상태 변경요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/admin/request/list',
              params: {
                ...PRODUCT_REQUEST_INITIAL_STATE,
                actions: ['STATUS'],
                statuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={productPriceRequestedCount}
          title='판매가격 변경요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/admin/request/list',
              params: {
                ...PRODUCT_REQUEST_INITIAL_STATE,
                actions: ['PRICE'],
                statuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={inboundRequestedCount}
          title='입고 요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/inbound/request',
              params: {
                ...inboundDefaultParams,
                dateCondition: 'CREATED_DAY',
                startDate: getCalcDate(new Date(), 1, 'month'),
                endDate: new Date(),
                inboundStatuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={inboundApprovedCount}
          title='입고 예정'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/inbound/request',
              params: {
                ...inboundDefaultParams,
                dateCondition: 'CREATED_DAY',
                startDate: getCalcDate(new Date(), 1, 'month'),
                endDate: new Date(),
                inboundStatuses: ['APPROVED'],
              },
            })
          }
        />

        <RequestItem
          value={couponIssueRequestedCount}
          title='쿠폰발행 요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/coupon/request',
              params: {
                ...couponDefaultParams,
                requestStatuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={vendorBusinessModifyRequestedCount}
          title='사업자정보 변경요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/admin/information/request',
              params: {
                ...informationDefaultParams,
                actions: ['BUSINESS'],
                statuses: ['REQUESTED'],
              },
            })
          }
        />

        <RequestItem
          value={vendorSettlementModifyRequestedCount}
          title='정산정보 변경요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/admin/information/request',
              params: {
                ...informationDefaultParams,
                actions: ['SETTLEMENT'],
                statuses: ['REQUESTED'],
              },
            })
          }
        />
        <RequestItem
          value={productDeliveryNoticeRequestedCount}
          title='배송공지 등록요청'
          unit='건'
          onClick={() =>
            onClickNavigateWithParams({
              path: '/delivery/notice',
              params: {
                ...deliveryNoticeDefaultParams,
                deliveryNoticeStatuses: ['SUSPENDED'],
              },
            })
          }
        />
      </Box>
    </Board>
  );
}

export default RequestSection;
