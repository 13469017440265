import { getDeliveryManageInitialState } from '@delivery/DeliveryManage/modules/constant';
import { getDeliveryNoticeInitialState } from '@delivery/DeliveryNotice/modules/constant';
import { getSettlementStatementInitialState } from '@settlement/IntegrateSettlement/modules/constant';

export const getSettlementRouteTableWithParameters = (notificationType: string) => {
  let path = '';
  let params = {};

  switch (notificationType) {
    case 'SETTLEMENT_TAX_INVOICE_REQUEST':
      path = '/integrate-settlement/list';
      params = getSettlementStatementInitialState();
      break;
    case 'SETTLEMENT_TAX_INVOICE_ISSUE':
      path = '/integrate-settlement/list';
      params = getSettlementStatementInitialState();
      break;
    case 'SETTLEMENT_TAX_INVOICE_CANCELED':
      path = '/integrate-settlement/list';
      params = getSettlementStatementInitialState();
      break;
    default:
      path = '/integrate-settlement/list';
      params = getSettlementStatementInitialState();
      break;
  }

  return { path, params };
};

export const getDeliveryRouteTableWithParameters = (notificationType: string) => {
  let path = '';
  let params = {};

  const defaultParams = getDeliveryManageInitialState();
  const deliveryNoticeDefaultParams = getDeliveryNoticeInitialState();

  switch (notificationType) {
    case 'DELIVERY_PREPARATION_DELAYED':
      path = '/delivery/manage';
      params = defaultParams;
      break;
    case 'DELIVERY_COMPLETION_DELAYED':
      path = '/delivery/manage';
      params = { ...defaultParams, endPointKey: 21, delayedDelivery: 'Y' };
      break;
    case 'DELIVERY_NOTICE_DEACTIVATED':
    case 'DELIVERY_NOTICE_ACTIVATED':
      path = '/delivery/notice';
      params = { ...deliveryNoticeDefaultParams };
      break;
    default:
      path = '/delivery/manage';
      params = defaultParams;
      break;
  }

  return { path, params };
};
