import { useId, useRef } from 'react';

import { flexRender, Row } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import type { TableProps } from './type';

import './table.css';

function VirtualTable<T>(props: TableProps<T>) {
  const { variants, tableLayout, wrapperClassName, onClick, emptyNode, ...config } = props;

  const uniqueKey = useId();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { rows } = config.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 51,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <div
      ref={tableContainerRef}
      className={`w-full overflow-auto tableWrapper ${wrapperClassName}`} // should be a fixed height
    >
      <table className={`main-table-virtualized ${variants}`}>
        <thead>
          {config.getHeaderGroups().map((hGroup) => (
            <tr key={`thead-${hGroup.id}-${uniqueKey}`} role='row'>
              {hGroup.headers.map((header) => (
                <th
                  key={header.id}
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<T>;

            return (
              <tr
                data-index={virtualRow.index}
                ref={(node) => rowVirtualizer.measureElement(node)}
                key={row.id}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                }}
                {...(onClick && {
                  className: 'row-link',
                  onClick: () => onClick?.(row),
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={{ width: cell.column.getSize() }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!rows.length && emptyNode && emptyNode}
    </div>
  );
}

export default VirtualTable;
